import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import NameCompany from "./bloks/NameCompany";
import "../Company.scss";
import Logo from "./bloks/Logo";
import LegalInfo from "./bloks/LegalInfo";
import { ContactInfo } from "./bloks/ContactInfo";
import BrandBranch from "./bloks/BrandBranch";
import AddressCompany from "./bloks/AddressCompany";
import { SocialMedia } from "./bloks/SocialMedia";
import WorkTime from "./bloks/WorkTime";
import PaymentMethods from "./bloks/PaymentMethods";
import Description from "./bloks/Description";
import FloatMenu from "./bloks/FloatMenu";
import NavigateBtnUp from "components/pages/application/common/NavigateBtnUp";
import { Button } from "primereact/button";
import { scroller } from "react-scroll";
import Category from "./bloks/Category";
import { reaction } from "mobx";
import { useParams } from "react-router";
import "./Company.scss";
import geoDataService from "../../../../services/geo/geo-category";

const Company = ({ geoStore, uiStore }) => {

    const { form } = geoStore;
    const { id } = useParams();

    const [showFloatMenu, setShowFloatMenu] = useState(false)
    const [showButtonUp, setShowButtonUp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [companyInfo, setCompanyInfo] = useState(null)

    const [saved, setSaved] = useState(false)

    const checkRequiredFields = () => {


        const fieldsError = [
            ...geoStore.checkPrimaryFields(),
            ...geoStore.checkArrayField(),
            ...geoStore.checkSetupFields(),
        ]


//todo переписать на ref
        if (fieldsError.length > 0) {
            fieldsError.map(field => {
                const element = document.getElementById(field);
                if (element) {
                    element.classList.add('p-invalid')
                }
                else {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: 'Ошибка',
                            detail: 'Невозможно сохранить данные. Выберите как минимум основные категории для площадок.',
                            sticky: true,
                        }
                    );

                }
            })

            const element = document.getElementsByClassName('p-invalid')

            if (element[0]) {
                scroller.scrollTo(element[0].id, {
                    offset: -180,
                    duration: 500,
                    delay: 0,
                    smooth: 'easeInOutQuart'
                });
            }
        } else {
            geoStore.saveCompanyData(id).then(result => {

                if (result === 'OK') {
                    setSaved(true);
                    uiStore.setMessage(
                        {
                            severity: 'success',
                            summary: 'Форма сохранена успешно',
                            detail: '',
                            sticky: false,
                            life: 2000,
                        }
                    );

/*
                    scroller.scrollTo('root', {
                        offset: 0,
                        duration: 500,
                        delay: 0,
                        smooth: 'easeInOutQuart'
                    });
*/
                }
                else {
                    if(result.error.data?.length > 0) {
                        result.error.data.map(item => {
                            uiStore.setMessage(
                                {
                                    severity: 'error',
                                    summary: result.error.description,
                                    detail: item.message,
                                    sticky: true,
                                }
                            )
                        });
                    }
                    else {
                        uiStore.setMessage(
                            {
                                severity: 'error',
                                summary: 'Ошибка',
                                detail: result.error.description,
                                sticky: true,
                            }
                        );

                    }
                }
            })
                .catch(error => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: 'Ошибка',
                            detail: error,
                        }
                    );

                })
        }
    }

    useEffect(() => {
        reaction(
            () => JSON.stringify(form),
            (result) => {
                //console.log(result)
                //console.log('reaction', JSON.parse(result));
            }
        );

        id && geoStore.getCompanyInfo(id).then(result => {
            setCompanyInfo(result)
            return geoStore.getCompanyData(id)
        }).then(_ => {
            setLoading(true)
        })

        const scrollListener = () => {
            const scrollTop = (window.pageYOffset || document.documentElement.scrollTop);

            if (scrollTop >= 100) {
                setShowFloatMenu(true);
                setShowButtonUp(true);
            } else {
                setShowFloatMenu(false);
                setShowButtonUp(false);
            }
        }

        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        }
    }, [])


    /*
        useEffect(() => {
            console.log('companyInfo', companyInfo)
        },[companyInfo])
    */

    return (

        <>
            <div className={cn('page__company-edit', 'page')}>
                <div className={'company-edit'}>
                    {showFloatMenu && <FloatMenu checkRequiredFields={checkRequiredFields}/>}
                    <div className={cn('title')}>
                        <h1>Редактирование компаний</h1>
                    </div>
                    {loading && <div className={cn('company-form__blocks')}>
                        {/*Название*/}

                        <div className={cn('block__wrapper')} id={'NameCompany'}>
                            <NameCompany/>
                        </div>

                        {/*Бренд и код филиала*/}
                        {/*
                        <div className={cn('block__wrapper')} id={'BrandBranch'} style={{ 'opacity': '0.2' }}>
                            <BrandBranch/>
                        </div>
*/}

                        {/*Адрес*/}
                        <div className={cn('block__wrapper')} id={'AddressCompany'}>
                            <AddressCompany/>
                        </div>

                        {/*Юридическая информация*/}
                        {/*
                        <div className={cn('block__wrapper')} id={'LegalInfo'} style={{ 'opacity': '0.2' }}>
                            <LegalInfo/>
                        </div>
*/}

                        {/*Категории*/}
                        <div className={cn('block__wrapper')} id={'Category'}>
                            <Category/>
                        </div>

                        {/*Контактная информация*/}
                        <div className={cn('block__wrapper')} id={'ContactInfo'}>
                            <ContactInfo/>
                        </div>

                        {/*Сайт и ссылки на социальные сети*/}
                        <div className={cn('block__wrapper')} id={'SocialMedia'}>
                            <SocialMedia/>
                        </div>

                        {/*Время работы и временные изменения в режиме*/}
                        <div className={cn('block__wrapper')} id={'WorkTime'}>
                            <WorkTime/>
                        </div>

                        {/*Способы оплаты*/}
                        {/*
                        <div className={cn('block__wrapper')} id={'PaymentMethods'} style={{ 'opacity': '0.2' }}>
                            <PaymentMethods/>
                        </div>
*/}

                        {/*Описание*/}
                        {/*
                        <div className={cn('block__wrapper')} id={'Description'} style={{ 'opacity': '0.2' }}>
                            <Description/>
                        </div>
*/}


                        {/*Логотип и обложка*/}
                        {/*
                        <div className={cn('block__wrapper')} id={'Logo'} style={{ 'opacity': '0.2' }}>
                            <Logo/>
                        </div>
*/}

                        <div className={cn('block__wrapper', 'pt-0')}>
                            <div className={cn('block')}>
                                <div className={cn('block__form')}>
                                    <div className={cn("form__row")}>
                                        <Button
                                            label='Сохранить'
                                            onClick={() => {
                                                checkRequiredFields()
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cn('block__wrapper', 'pt-0')}>
                            <div className={cn('block')}>
                                <div className={cn('block__form')}>
                                    <div className={cn("form__row")}>
                                        <Button
                                            label='Скопировать данные с Яндекса'
                                            onClick={() => {
                                                geoDataService.copyData(companyInfo.id, {
                                                    source: 'yandex',
                                                    merge: false
                                                })
                                                    .then(result => {
                                                        if(result === 'OK') {
                                                            uiStore.setMessage(
                                                                {
                                                                    severity: 'success',
                                                                    summary: 'Успех',
                                                                    detail: 'Данные скопированы успешно',
                                                                    sticky: false,
                                                                    life: 2000,
                                                                }
                                                            );
                                                        }
                                                        else {
                                                            uiStore.setMessage(
                                                                {
                                                                    severity: 'error',
                                                                    summary: 'Ошибка',
                                                                    detail: 'Ошибка при копировании данных',
                                                                }
                                                            );
                                                        }
                                                    })
                                            }}
                                        />
                                        &nbsp;
                                        <Button
                                            label='Скопировать данные с 2ГИС'
                                            onClick={() => {
                                                geoDataService.copyData(companyInfo.id, {
                                                    source: '2gis',
                                                    merge: false
                                                })
                                                    .then(result => {
                                                        if(result === 'OK') {
                                                            uiStore.setMessage(
                                                                {
                                                                    severity: 'success',
                                                                    summary: 'Успех',
                                                                    detail: 'Данные скопированы успешно',
                                                                    sticky: false,
                                                                    life: 2000,
                                                                }
                                                            );
                                                        }
                                                        else {
                                                            uiStore.setMessage(
                                                                {
                                                                    severity: 'error',
                                                                    summary: 'Ошибка',
                                                                    detail: 'Ошибка при копировании данных',
                                                                }
                                                            );
                                                        }
                                                    })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cn('block__wrapper', 'pt-0')}>
                            <div className={cn('block')}>
                                <div className={cn('block__form')}>
                                    <div className={cn("form__row")}>
                                        <Button
                                            label='Запустить синхронизацию с Яндексом'
                                            onClick={() => {
                                                geoDataService.syncData(companyInfo.id, 'yandex')
                                                    .then(result => {
                                                        if(result === 'OK') {
                                                            uiStore.setMessage(
                                                                {
                                                                    severity: 'success',
                                                                    summary: 'Успех',
                                                                    detail: 'Данные синхронизированы успешно',
                                                                    sticky: false,
                                                                    life: 2000,
                                                                }
                                                            );
                                                        }
                                                        else {
                                                            uiStore.setMessage(
                                                                {
                                                                    severity: 'error',
                                                                    summary: 'Ошибка',
                                                                    detail: 'Ошибка при синхронизации данных',
                                                                }
                                                            );
                                                        }
                                                    })
                                            }}
                                        />
                                        &nbsp;
                                        <Button
                                            label='Запустить синхронизацию с 2ГИС'
                                            onClick={() => {
                                                geoDataService.syncData(companyInfo.id, '2gis')
                                                    .then(result => {
                                                        if(result === 'OK') {
                                                            uiStore.setMessage(
                                                                {
                                                                    severity: 'success',
                                                                    summary: 'Успех',
                                                                    detail: 'Данные синхронизированы успешно',
                                                                    sticky: false,
                                                                    life: 2000,
                                                                }
                                                            );
                                                        }
                                                        else {
                                                            uiStore.setMessage(
                                                                {
                                                                    severity: 'error',
                                                                    summary: 'Ошибка',
                                                                    detail: 'Ошибка при синхронизации данных',
                                                                }
                                                            );
                                                        }
                                                    })

                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {showButtonUp && <NavigateBtnUp/>}
                    </div>}

                </div>
            </div>
        </>
    )

}

export default inject('geoStore', 'uiStore')(observer(Company));