import React, { useEffect, useState } from 'react';
import okAnalyticsService from "../../../../services/okAnalytics";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterDateCompare } from "../Filter";
import './DataCompare.scss';
import { AD_PLATFORM } from "../../../../models/ad-platform";
import { reaction } from "mobx";

const DataCompare = ({ analyticsStore, userStore, uiStore }) => {
    const [loading, setLoading] = useState(false);
    const [adPlatform, setAdPlatform] = useState(AD_PLATFORM);
    const [total, setTotal] = useState([]);
    const [items, setItems] = useState([]);

    const { filterDateCompare } = analyticsStore;

    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'analytics_data-compare',
            target: 'page',
            event: 'open',
        });

    }, []);


    useEffect(() => {
        setLoading(true);

        analyticsStore.getDataCompare()
            .then((result) => {
                if(result.total) {
                    setTotal(result.total);
                }
                if(result.items) {
                    setItems(result.items)
                }
                setLoading(false)
            })

        setAdPlatform(()=>{
            return AD_PLATFORM.filter(item => item.value !== 'none')
        })

    }, [
        JSON.stringify(filterDateCompare.date),
        JSON.stringify(filterDateCompare.branches)
    ]);

    useEffect(() => {
        reaction(()=>{
                return filterDateCompare.openBlock
            },
            (result) => {
                console.log('filterDateCompare.openBlock', result)
            }
        )
        //filterDateCompare.openBlock
        ///console.log(filterDateCompare.sources)

    }, []);


    return (<>
        <div className={cn('page__analytics', 'page')}>
            <div className={cn('title')}>
                <h1>Аналитика</h1>
            </div>
            <div className={cn('analytics-tabs')}>
                <Tabs/>
            </div>
            <div className={cn('analytics-filter')}>
                <FilterDateCompare loading={loading}/>
            </div>
            <div className={cn('analytics-table', 'table', 'data-compare-table')}>
                <div className={cn('table__wrapper')}>
                    <div className={cn('table')}>
                        <div className={cn('table__header')}>
                            <div className={cn('table__row')}>
                                <div className={cn('table__cell', 'cell__title')}>Филиал</div>
                                {adPlatform.map((ad, key) => {
                                    return (
                                        <div className={cn('table__cell')} key={key}>
                                            <div className={cn('ad__ico')}>
                                                <img src={ad.ico} alt={ad.name} width={21} height={21}/>
                                                <div>{ad.shortName}</div>
                                            </div>
                                        </div>
                                   );
                                })}
                            </div>
                        </div>
                        {total &&
                            <div className={cn('table__row', 'row')} >
                                <div className={cn('table__cell', 'cell', 'cell__title')}>
                                    <div className={cn('ico', 'ico__bag')}></div>
                                    <div>Средний рейтинг</div>
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['avito'] ? <TotalRating data={total['avito']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['google'] ? <TotalRating data={total['google']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['yandex'] ? <TotalRating data={total['yandex']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['2gis'] ? <TotalRating data={total['2gis']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['otzovik'] ? <TotalRating data={total['otzovik']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['flamp'] ? <TotalRating data={total['flamp']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['irecommend'] ? <TotalRating data={total['irecommend']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['zoon'] ? <TotalRating data={total['zoon']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['vkontakte'] ? <TotalRating data={total['vkontakte']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['prodoctorov'] ? <TotalRating data={total['prodoctorov']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['docdoc'] ? <TotalRating data={total['docdoc']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['yell'] ? <TotalRating data={total['yell']}/> : <>-</>}
                                </div>
                                <div className={cn('table__cell')}>
                                    {total['napopravku'] ? <TotalRating data={total['napopravkus']}/> : <>-</>}
                                </div>
                            </div>
                        }
                        <div className={cn('table__body')}>
                            {items.length > 0 && items.map((item, key)=>{
                                return (
                                    <div className={cn('table__row')} key={key}>
                                        <div className={cn('table__cell', 'cell', 'cell__title')}>
                                            <div className={cn('ico', 'ico__bag')}></div>
                                            <div title={item.branch.title}>
                                                <TitleBranch branchTitle={item.branch.title}/>
                                            </div>
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['avito'] ? <TotalRating data={item.sources['avito']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['google'] ? <TotalRating data={item.sources['google']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['yandex'] ? <TotalRating data={item.sources['yandex']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['2gis'] ? <TotalRating data={item.sources['2gis']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['otzovik'] ? <TotalRating data={item.sources['otzovik']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['flamp'] ? <TotalRating data={item.sources['flamp']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['irecommend'] ? <TotalRating data={item.sources['irecommend']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['zoon'] ? <TotalRating data={item.sources['zoon']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['vkontakte'] ? <TotalRating data={item.sources['vkontakte']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['prodoctorov'] ? <TotalRating data={item.sources['prodoctorov']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['docdoc'] ? <TotalRating data={item.sources['docdoc']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['yell'] ? <TotalRating data={item.sources['yell']}/> : <>-</>}
                                        </div>
                                        <div className={cn('table__cell')}>
                                            {item.sources['napopravku'] ? <TotalRating data={item.sources['napopravkus']}/> : <>-</>}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}


const TitleBranch = ({branchTitle}) => {

    const [title, title2] = branchTitle.split(' - ');

    return (
        <>
            <div>{title}</div>
            {title2 && <><br/><div>{title2}</div></>}
        </>
    )
}

const TotalRating = ({data}) => {

    const setDiffBlock = (value) => {
        if(value < 0 ) {
            return <span className={cn('diff', 'diff__down')}>{value}</span>
        }
        else if (value === 0) {
            return <span className={cn('diff')}>{value}</span>
        }
        else {
            return <span className={cn('diff', 'diff__up')}>+{value}</span>
        }
    }

    return (
        <>
            <span>{data.close.rating}</span>
            {setDiffBlock(data.diff.rating)}
        </>
    )
}

export default inject('analyticsStore', 'userStore', 'uiStore')(observer(DataCompare));