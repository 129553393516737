import authService from "./auth";
import {serialize} from "../helpers/index";
//import dataCompare from "./../tmp/data_compare.json"

class AnalyticsService {

    /**
     * аналитика трафик
     * @param {Object} filter
     * @returns {Promise<* | undefined>}
     */
    getTraffic = (filter) => {
        const queryString = serialize(filter);
        return authService.get(
            `/extreview/stats/views?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);
    }

    getDataCompare = (filter) => {

        //return Promise.resolve(dataCompare);

        const queryString = serialize(filter);
        return authService.get(
            `/extreview/reviews/report/branches/sources?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);

    }

    /**
     * аналитика допольнительный трафик (Показ номера телефона, Построение маршрута, Переходы на сайт)
     * @param filter
     */
    getAdditionalTraffic = (filter) => {
        const queryString = serialize(filter);
        let outData = [];
        return Promise.all([
            this.getShowPhoneNumberTraffic(queryString),
            this.getRouteTraffic(queryString),
            this.getConversionSiteTraffic(queryString),
        ]).then(([
                     phoneNumberTrafficResult,
                     routeTrafficResult,
                     conversionSiteTrafficResult
                 ]) => {

            outData = phoneNumberTrafficResult.series.map((item, index) => {
                return {
                    date: item.startDate,
                    phoneNumberTraffic: item.phoneClicks,
                    routeTraffic: routeTrafficResult.series[index].routeClicks,
                    conversionSiteTraffic: conversionSiteTrafficResult.series[index].siteClicks,
                }
            });

            return outData;
        });
    }

    /**
     * Показ номера телефона
     * @param queryString
     * @returns {Promise<* | undefined>}
     */
    getShowPhoneNumberTraffic = (queryString) => {
        return authService.get(
            `/extreview/stats/phones?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);

    }

    /**
     * Построение маршрута
     * @param queryString
     * @returns {Promise<* | undefined>}
     */
    getRouteTraffic = (queryString) => {
        return authService.get(
            `/extreview/stats/routes?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);
    }

    /**
     * Переходы на сайт
     * @param queryString
     * @returns {Promise<* | undefined>}
     */
    getConversionSiteTraffic = (queryString) => {
        return authService.get(
            `/extreview/stats/sites?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);
    }

    getQrList = (filter) => {
        const queryString = serialize(filter);
        return authService.get(
            `/qrfeedback/report/branches?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);

    }

    /**
     * аналитика отчет по филиалам
     * @param {Object} filter
     * @returns {Promise<* | undefined>}
     */
    getReviewsList = (filter) => {
        const queryString = serialize(filter);
        return authService.get(
            `/extreview/reviews/report/branches?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);
    }

    getReviewsByPlatformList = (filter) => {
        const queryString = serialize(filter);
        return authService.get(
            `/extreview/reviews/report/adplatforms?${queryString}`
        )
            .then((result) => result)
            .catch(e => e);
    }

    /**
     * аналитика отчет по филиалам
     * @param {Object} filter
     * @returns {Promise<* | undefined>}
     */
    getApsBranchList = (filter) => {
        const queryString = serialize(filter);
        return authService.get(
            `/applications/report/branches?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);
    }

    /**
     * аналитика отчет по сотрудникам
     * @param {Object} filter
     * @returns {Promise<* | undefined>}
     */
    getApsManagerList = (filter) => {
        const queryString = serialize(filter);
        return authService.get(
            `/applications/report/managers?${queryString}`,
        )
            .then((result) => result)
            .catch(e => e);
    }

    downloadXlsReport = (filter) => {
        return authService.post(
            `/applications/report/export`,
            filter
        )
            .then((result) => result)
            .catch(e => e);
    }

    downloadXlsReportQr = (filter) => {
        return authService.post(
            `/qrfeedback/report/branches/export`,
            filter
        )
            .then((result) => result)
            .catch(e => e);
    }

    downloadXlsReportReport = (filter) => {
        return authService.post(
            `/extreview/reviews/report/export`,
            filter
        )
            .then((result) => result)
            .catch(e => e);
    }

}

const analyticsService = new AnalyticsService();
export default analyticsService;
